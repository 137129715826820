import {IBenefitValue} from "./containers/BenefitForm";
import {IFeature} from "./components/FeaturesTable";
import {IVariables} from "./screen/Variables";

export class Api {
  private token: string | undefined;

  public async authorize(username: string, password: string){
    try {
      const response = await fetch(this.url('/login_check'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.location.hostname
        },
        body: JSON.stringify({username, password})
      })

      const { token } = await response.json()

      this.token = token

      return token
    } catch (e) {
      throw new Error('Failed to fetch: ' + e)
    }
  }

  public async fetchResource(resource: string){
    const response = await this.authorizedFetch(this.url(`/${resource}/`), {
      method: 'GET'
    })

    return response
  }

  public async syncProducts(){
    const response = await this.authorizedFetch(this.url('/sync'), {
      method: 'GET'
    })

    return response
  }

  public async saveBenefit(benefit: IBenefitValue){
    const response = await this.authorizedFetch(this.url('/benefit'), {
      method: 'POST',
      body: JSON.stringify(benefit)
    })

    return response
  }

  public async saveVar(benefit: IVariables){
    const response = await this.authorizedFetch(this.url('/vars'), {
      method: 'POST',
      body: JSON.stringify(benefit)
    })

    return response
  }

  public async saveConfig(config: any){
    const response = await this.authorizedFetch(this.url('/config'), {
      method: 'POST',
      body: JSON.stringify(config)
    })

    return response
  }

  public async saveSchemaConfig(config: any){
    const response = await this.authorizedFetch(this.url('/schema/'), {
      method: 'PUT',
      body: JSON.stringify(config)
    })

    return response
  }


  public async saveFeature(feature: IFeature){
    const response = await this.authorizedFetch(this.url('/feature'), {
      method: 'POST',
      body: JSON.stringify(feature)
    })

    return response
  }

  public async removeResource(resource: string, id: number){
    const response = await this.authorizedFetch(this.url(`/${resource}/${id}`), {
      method: 'DELETE',
    })

    return response
  }

  public async editResource(resource: string, resourceObject: any, method = 'POST'){
    const response = await this.authorizedFetch(this.url(`/${resource}/${resourceObject.id}`), {
      method: method,
      body: JSON.stringify(resourceObject)
    })

    return response
  }

  public async addImage(formData: FormData){
    const response = await this.authorizedFetch(this.url('/image/'), {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      }
    })
    return response
  }

  public setToken(token: string){
    this.token = token
  }

  public isAuthorized(){
    return !!this.token
  }

  public url(path: string = ''){
    return process.env.REACT_APP_API_URL + path
  }

  public authorizedFetch(input: RequestInfo, init?: RequestInit){
    if(!this.isAuthorized){
      throw new Error('Not authorized')
    }

    return fetch(input, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': window.location.hostname
      },
        ...init
    })
  }
}

export default new Api()