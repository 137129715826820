import { AnyAction } from "redux";

export default function (store: UserStore = initial, action:AnyAction):UserStore {
  switch (action.type) {
    case 'Authorize':
      return {
        ...store,
        authorized: true
      }
    case 'Check':
      return {
        ...store,
        checked: store.checked.includes(action.id)? [...store.checked.filter(c => c !==action.id)]:[...store.checked, action.id]
      }
    case 'RemoveCheck':
      return {
        ...store,
        checked: []
      }

    default:
      return {
        ...store
      }
  }
}

export interface UserStore {
  authorized: boolean
  checked: number[]
}

const initial:UserStore = {
  authorized: false,
  checked: []
}