import { Dispatch } from 'redux'
import { State } from "../reducers/rootReducer";
import { toast } from "react-toastify";

export default function () {
  return function (dispatch: Dispatch, getState: () => State) {
    localStorage.clear()
    setTimeout(() => {
      toast.success('Pomyślnie wylogowano')
    }, 1000)
  }
}