import React, { Suspense } from 'react';
import {Router, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify'
import api from "./api";
import Panel from "./containers/Panel";

const history = createBrowserHistory()

const LoadableComponent = (name:string): React.FC<any> => {
  const Component = React.lazy(() => import(`./screen/${name}`))

  return () => <Suspense fallback={<></>}>
    <Component/>
  </Suspense>
}

const Protected = (name: string): React.FC<any> => {
  const Component = LoadableComponent(name)

  const exp = localStorage.getItem('exp')
  const token = localStorage.getItem('accessToken')

  if(!token || !exp || new Date(Number(exp) * 1000) < new Date()){
    history.push('/login')
  }

  api.setToken(token as string)

  return () => {
    return <Panel><Component/></Panel>
  }
}

function App() {
  return (<>
      <ToastContainer autoClose={5000} />
      <Router history={history}>
        <Switch>
          <Route exact={true} path={'/'} component={Protected('Dashboard') as any}/>
          <Route exact={true} path={'/login'} component={LoadableComponent('Login') as any}/>
          <Route exact={true} path={'/benefity'} component={Protected('Benefits') as any}/>
          <Route exact={true} path={'/benefit'} component={Protected('BenefitsAdd') as any}/>
          <Route exact={true} path={'/pytania'} component={Protected('Questions') as any}/>
          <Route exact={true} path={'/pytanie'} component={Protected('Question') as any}/>
          <Route exact={true} path={'/tresci'} component={Protected('Content') as any}/>
          <Route exact={true} path={'/tresc'} component={Protected('ContentEdit') as any}/>
          <Route exact={true} path={'/produkty'} component={Protected('Products') as any}/>
          <Route exact={true} path={'/produkt'} component={Protected('Product') as any}/>
          <Route exact={true} path={'/zamowienia'} component={Protected('Orders') as any}/>
          <Route exact={true} path={'/zamowienie'} component={Protected('Order') as any}/>
          <Route exact={true} path={'/cechy'} component={Protected('Features') as any}/>
          <Route exact={true} path={'/cecha'} component={Protected('Feature') as any}/>
          <Route exact={true} path={'/przewoznicy'} component={Protected('Carriers') as any}/>
          <Route exact={true} path={'/przewoznik'} component={Protected('Carrier') as any}/>
          <Route exact={true} path={'/feedback'} component={Protected('Feedback') as any}/>
          <Route exact={true} path={'/kupony'} component={Protected('Cupons') as any}/>
          <Route exact={true} path={'/konfigurator'} component={Protected('Config') as any}/>
          <Route exact={true} path={'/szablony'} component={Protected('EmailSchema') as any}/>
          <Route exact={true} path={'/szablon'} component={Protected('EmailSchemaEdit') as any}/>
          <Route exact={true} path={'/zmienne'} component={Protected('Variables') as any}/>
          <Route exact={true} path={'/zmienna'} component={Protected('VariablesAdd') as any}/>
        </Switch>
      </Router>
    </>);
}

export default App;
