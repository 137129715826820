import React from "react";
import {useDispatch} from "react-redux";
import logout from "../actions/logout";
import {useHistory} from "react-router";

function Navigation() {
  const dispatch = useDispatch()
  const history = useHistory()

  const logoutClick = () => {
    dispatch(logout())
    history.push('/login')
  }

  return <>

    <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white" id="mainNav">


      <header className="header-logo">
        <a className="nav-link text-center mr-lg-3 hidden-xs" id="sidenavToggler"><i className="ti-align-left"></i></a>
        <a className="navbar-brand" href="/">Pro Eco</a>
      </header>


      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="ti-align-left"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarResponsive">


        <div className="navbar-side">
          <ul className="navbar-nav navbar-sidenav" id="exampleAccordion">

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Produkty">
              <a className="nav-link" href={'/produkty'} >
                <i className="fa i-cl-3 padd-r-5 width-22 fa-shopping-cart"></i>
                <span className="nav-link-text">Produkty</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Zamówienia">
              <a className="nav-link" href={'/zamowienia'} >
                <i className="fa i-cl-2 padd-r-5 width-22 fa-credit-card"></i>
                <span className="nav-link-text">Zamówienia</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Cechy">
              <a className="nav-link" href={'/cechy'} >
                <i className="fa i-cl-9 padd-r-5 width-22 fa-code-fork"></i>
                <span className="nav-link-text">Cechy produktu</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Przewoźnicy">
              <a className="nav-link" href={'/przewoznicy'} >
                <i className="fa i-cl-11 padd-r-5 width-22 fa-truck"></i>
                <span className="nav-link-text">Przewoźnicy</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Pytania">
              <a className="nav-link" href={'/pytania'}>
                <i className="fa padd-r-5 width-22 fa fa-question"></i>
                <span className="nav-link-text">Pytania</span>
              </a>
            </li>


            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Benefity">
              <a className="nav-link" href={'/benefity'}>
                <i className="fa i-cl-5 padd-r-5 width-22 fa fa-list-ol"></i>
                <span className="nav-link-text">Benefity</span>
              </a>

            </li>


            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Treści">
              <a className="nav-link" href={'/tresci'} >
                <i className="fa i-cl-4 padd-r-5 width-22 fa-list-alt"></i>
                <span className="nav-link-text">Treści</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Zmienne">
              <a className="nav-link" href={'/zmienne'} >
                <i className="fa i-cl-16 padd-r-5 width-22 fa-list-alt"></i>
                <span className="nav-link-text">Zmienne</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Feedback">
              <a className="nav-link" href={'/feedback'} >
                <i className="fa i-cl-3 padd-r-5 width-22 fa-comment"></i>
                <span className="nav-link-text">Feedback</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Kupony">
              <a className="nav-link" href={'/kupony'} >
                <i className="fa i-cl-2 padd-r-5 width-22 fa-tag"></i>
                <span className="nav-link-text">Kupony</span>
              </a>
            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Konfigurator">
              <a className="nav-link" href={'/konfigurator'} >
                <i className="fa i-cl-6 padd-r-5 width-22 fa-gears"></i>
                <span className="nav-link-text">Konfigurator</span>
              </a>

            </li>

            <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Szablony">
            <a className="nav-link" href={'/szablony'} >
              <i className="fa i-cl-11 padd-r-5 width-22 fa-envelope"></i>
              <span className="nav-link-text">Szablony</span>
            </a>

          </li>


          </ul>
        </div>


      </div>
      <button className="btn btn-outline-danger" onClick={logoutClick}>wyloguj</button>
    </nav>
  </>
}

export default Navigation