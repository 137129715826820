import React, {useEffect} from "react";
import Navigation from "../components/Navigation";

function Panel({children}: IPanel) {

  useEffect(() => {
    document.body.classList.add('fixed-nav')
    return () => {
      document.body.classList.remove('fixed-nav')
    }
  },[])

  return <>
    <Navigation/>
    {children}
  </>
}

export default Panel
export interface IPanel {
  children: React.ReactChild
}